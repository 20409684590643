import { AbsoluteCircle } from "../../../components/main/AbsoluteCircle";
import { GradientBackground } from "../../../components/main/GradientBackground";
import { PluginCard } from "../../../components/main/PluginCard";

export const AllPlugins = () => {
  return (
    <>
      <GradientBackground
        color={"787A93"}
        decoratives={
          <>
            <AbsoluteCircle position="top-left" />
            <AbsoluteCircle position="bottom-right" />
          </>
        }
      >
        <p className="m-0 text-center text-white large-title fw-bold mb-5">
          OxaPay E-commerce
          <br />
          plugins and apps
        </p>
      </GradientBackground>

      <div className="px-5">
        <div
          className="justify-content-center align-items-center w-100 rounded-35px overflow-hidden py-15"
          style={{ background: "#DFDFE7" }}
        >
          <div className="container-fluid max-width-1550">
            <div className="my-15">
              <div className="m-auto" style={{ maxWidth: "1000px" }}>
                <h1 className="fs-4x text-dark text-center fw-bold">
                  All Plugins
                </h1>
                <p className="fs-3 text-gray-700 mb-20">
                  Integrate the cryptocurrency payment gateway into your
                  E-Commerce platform quickly and easily using OxaPay plugins
                  and applications, requiring just a few clicks.
                </p>
              </div>

              <div className="row">
                <div className="col-12 col-md-3">
                  <PluginCard name="woocommerce" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="clientexec" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="blesta" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="wisecp" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="whmcs" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="easy-digital-downloads" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="prestashop" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="paid-memberships-pro" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="gravity-forms" />
                </div>
                <div className="col-12 col-md-3">
                  <PluginCard name="restrict-content-pro" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
