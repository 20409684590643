export const networksNameMap: { [key: string]: string } = {
  ERC20: "ethereum:",
  TRC20: "tron:",
  Bitcoin: "bitcoin:",
  BitcoinCash: "bitcoincash:",
  Ethereum: "ethereum:",
  Dogecoin: "dogecoin:",
  Litecoin: "litecoin:",
  BEP20: "ethereum:",
  DigiByte: "digibyte:",
  Monero: "monero:",
  Ton: "ton://transfer/",
  Polygon: "ethereum:",
  Solana: "solana:",
  Base: "ethereum:",
};

export const networksAmountMap: { [key: string]: number } = {
  ERC20: 1,
  TRC20: 1,
  Bitcoin: 1,
  BitcoinCash: 1,
  Ethereum: 1,
  Dogecoin: 1,
  Litecoin: 1,
  BEP20: 1,
  DigiByte: 1,
  Monero: 1,
  Ton: 1000000000,
  Polygon: 1000000000000000000,
  Solana: 1,
  Base: 1,
};

export const networkschainIdMap: { [key: string]: string } = {
  ERC20: "",
  TRC20: "",
  Bitcoin: "",
  BitcoinCash: "",
  Ethereum: "",
  Dogecoin: "",
  Litecoin: "",
  BEP20: "@56",
  DigiByte: "",
  Monero: "",
  Ton: "",
  Polygon: "@137",
  Solana: "",
  Base: "@8453",
};

export const networksAmountNameMap: { [key: string]: string } = {
  ERC20: "?amount=",
  TRC20: "?amount=",
  Bitcoin: "?amount=",
  BitcoinCash: "?amount=",
  Ethereum: "?amount=",
  Dogecoin: "?amount=",
  Litecoin: "?amount=",
  BEP20: "?amount=",
  DigiByte: "?amount=",
  Monero: "?amount=",
  Ton: "?amount=",
  Polygon: "?value=",
  Solana: "?amount=",
  Base: "?amount=",
};

type CoinMapping = {
  [coin: string]: string;
};

type NetworkMappings = {
  [network: string]: CoinMapping;
};

export const getTokenContractAddress = (
  coin: string,
  network: string
): string => {
  const mappings: NetworkMappings = {
    ERC20: {
      USDT: "%26token=0xdac17f958d2ee523a2206206994597c13d831ec7",
      USDC: "%26token=0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    },
    TRC20: {
      USDT: "%26token=TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
    },
    BEP20: {
      USDT: "%26token=0x55d398326f99059ff775485246999027b3197955",
      SHIB: "%26token=0x2859e4544c4bb03966803b044a93563bd2d0dd4d",
    },
    Polygon: {
      USDT: "%26token=0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
      DAI: "%26token=0x8f3cf7ad23cd3cadbd9735aff958023239c6a063",
    },
    Ton: {
      USDT: "%26token=EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs",
      NOT: "%26token=EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT",
      DOGS: "%26token=EQCvxJy4eG8hyHBFsZ7eePxrRsUQSFE_jpptRAYBmcG_DOGS",
    },
  };

  return mappings[network]?.[coin] || "";
};
